import React from 'react';
import { Helmet } from 'react-helmet';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MetaTags } from './components/MetaTags';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Steam Dolar Çevirici</title>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous" />
      <meta name="description" content="Steam Dolar Çevirici sayesinde Steam'deki oyunların kaç TL olduğunu kolayca öğrenebilirsiniz." />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <link rel="canonical" href="https://steamdovizcevirici.com/" />
      <meta property="og:locale" content="tr_TR" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Steam Döviz Çevirici | Steam Dolar Çevirici" />
      <meta property="og:description" content="Steam Dolar Çevirici sayesinde Steam'deki oyunların kaç TL olduğunu kolayca öğrenebilirsiniz." />
      <meta property="og:url" content="https://steamdovizcevirici.com/" />
      <meta property="og:site_name" content="Steam Dolar Çevirici" />
    </Helmet>
    <App />
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossorigin="anonymous"></script>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
