import './App.css';
import { Footer } from './components/Footer';
import { scrapeThis } from './scraping';
import { useEffect, useState } from 'react';

function App() {

  const [currencies, setCurrencies] = useState([])

  useEffect(() => {
    scrapeThis()
      .then(function (response) {
        setCurrencies(response.data?.items)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  const convertToTRYFormat = value => value && value.replace(',', '.')

  function convertCurrency(event) {
    const dolarAmount = parseFloat(event.target.value);

    const bankTLAmount = document.querySelectorAll('.bankTLAmount')
    const tlAmountList = document.querySelectorAll('.tlAmount')
    const dolarAmountCell = document.querySelectorAll('.dolarAmountCell')

    if (bankTLAmount.length > 0) {
      for (let i = 0; i < bankTLAmount.length; i++) {
        dolarAmountCell[i].innerText = dolarAmount ? `${dolarAmount} dolar` : '-';
        const currency = parseFloat(convertToTRYFormat(bankTLAmount[i].innerText.replace('₺', '')))

        const carpilanTutar = (dolarAmount * currency)

        const totalAmount = dolarAmount ? (carpilanTutar).toLocaleString('tr', {
          style: 'currency',
          currency: 'TRY',
        }) : '-'

        tlAmountList[i].innerText = `${totalAmount}`
      }
    }
  }

  const converToLocaleString = (price) => {
    let parsedNumber = parseFloat(price);
    let formattedNumber = parsedNumber.toFixed(2);
    return formattedNumber
  }

  const RenderBankRow = (value) => (
    <tr>
      <th scope="row">{value.title.replace('Döviz Kuru', '')}</th>
      <td className='bankTLAmount'>₺{converToLocaleString(value.currencies[0]?.ask)}</td>
      <td id="dolarAmountCell" className='dolarAmountCell'>-</td>
      <td id="tlAmount" className='tlAmount'>-</td>
    </tr>
  )


  return (
    <div className='container pb-5'>
      <div className='row d-flex justify-content-center align-items-center my-5 mb-5'>
        <div className='col-lg-12'>
          <div className='card border-0 shadow-lg p-lg-5 p-2 py-lg-5 py-4 custom-card'>
            <div className='text-center'>
              <h4>
                <label for="dolarAmount">Steam Döviz Çevirici:</label>
              </h4>
              <input type="number" id="dolarAmount" step="0.01" onChange={convertCurrency} placeholder="Steam’deki dolar fiyatı:" />
            </div>

            {
              !currencies ? <div class="spinner-grow" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
                :
                <table class="table table-responsive table-striped d-block" id='resultTable'>
                  <tbody className='table-responsive-tbody'>
                    <tr className='dodgerblue'>
                      <th>Banka Adı</th>
                      <th>Banka Kuru</th>
                      <th>Dolar Miktarı</th>
                      <th>TL Karşılığı</th>
                    </tr>
                    {
                      currencies.map(function (value, index, array) {
                        return RenderBankRow(value)
                      })
                    }
                  </tbody>
                </table>
            }
          </div>

        </div>
      </div>

      <Footer />
    </div>
  )

  return (
    currencies ?
      <div className="converter" style={{ marginTop: 1000, marginBottom: 50 }}>
        <label for="dolarAmount">Steam Döviz Çevirici:</label>
        <input type="number" id="dolarAmount" step="0.01" onChange={convertCurrency} placeholder='Lütfen tutar girin' />
        <table id="resultTable">
          <tr>
            <th>Banka Adı</th>
            <th>Banka Kuru</th>
            <th>Dolar Miktarı</th>
            <th>TL Karşılığı</th>
          </tr>

          {
            currencies.map(function (value, index, array) {
              return RenderBankRow(value)
            })
          }

        </table>
      </div>
      : <div>Veri yok</div>
  );
}

export default App;
