export const Footer = () => {
    return (
        <div className="row text-center">
            <div className="col-12">
                <div>
                    <a href="mailto:reklam@steamdovizcevirici.com" className="footer-email link text-link text-primary">reklam@steamdovizcevirici.com</a>
                </div>
            </div>
        </div>
    )
}